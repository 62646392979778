import React, { Component } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core';
import Head from 'next/head';
import Cookies from 'universal-cookie';
import { withTranslation } from '@i18n';
import {
  isLanding, ui as theme, popUpScrollForbiden, toast, languageReturner,
} from '../helpers';
// Components
import {
  Header, Sidebar, Footer, ConditionallyRender, CookiePanel, ToastContainerHOC, Popup,
} from '../components';
// Css and Fonts
import '../assets/scss/_colors-classes.scss';
import '../assets/css/tachyons.css';
import '../assets/css/font-awesome.css';
import '../assets/scss/dashboard.scss';
import '../assets/scss/buttons.scss';
import '../assets/scss/mobile/index.scss';
import { infoConstants } from '../redux/constants';

if (process.env.NODE_ENV === 'development') {
  require('../assets/scss/index.scss');
  require('./Layout.scss');
  require('./mobile/index.scss');
}
class Layout extends Component<any, any> {
  windowWidth: any;

  constructor(props: any) {
    super(props);
    this.windowWidth = props.windowWidth;
    this.langAttributeReturner = this.langAttributeReturner.bind(this);
  }

  static getInitialProps = async () => ({
    namespacesRequired: ['common'],
  });

  componentDidMount() {
    const {
      location, windowWidth, openMobileSidebar, hideMobileSidebar,
    } = this.props;
    if (windowWidth < 1280) {
      hideMobileSidebar();
    } else {
      openMobileSidebar();
    }
  }

  componentWillReceiveProps(nextProps: any) {
    const { windowWidth } = this.props;
    popUpScrollForbiden(windowWidth, [nextProps.openedMobileSidebar]);
    if (nextProps.windowWidth !== (this.props as any).windowWidth) {
      if (nextProps.windowWidth < 1280) {
        (this.props as any).hideMobileSidebar();
      } else {
        (this.props as any).openMobileSidebar();
      }
    }
    if (nextProps.language && document) {
      document.documentElement.setAttribute('lang', this.langAttributeReturner(nextProps.language));
    }
  }

  langAttributeReturner(lang: any) {
    switch (lang) {
      case 'ae': return 'ar';
      case 'en': return 'en';
      case 'de': return 'de';
      case 'es': return 'es';
      case 'id': return 'id';
      case 'it': return 'it';
      case 'kr': return 'ko';
      case 'pt': return 'pt';
      case 'ru': return 'ru';
      case 'zh': return 'zh';
      default: return 'en';
    }
  }

  render() {
    const {
      isVisibleMobileMenu, location, asPath, children, cookieAccept, windowWidth, openedMobileSidebar, className = '', language, email, t, id
    } = this.props;
    const legalFootersLocation = [`${languageReturner(language)}/login`, `${languageReturner(language)}/register`, `${languageReturner(language)}/change-password`, `${languageReturner(language)}/forgot-password`, `${languageReturner(language)}/registration-completed`, `${languageReturner(language)}/email-verification`];
    const cookies = new Cookies();
    const userID = id || cookies.get('userID');
    const emailValue = email || cookies.get('email');
    return (
      <MuiThemeProvider theme={theme}>
        <ConditionallyRender client>
          <Head>
            <link
              rel="alternate"
              hrefLang={this.langAttributeReturner(language)}
              href={`${process.env.SITE_URL}${asPath}`}
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href={`${process.env.SITE_URL}${asPath.replace(
                `/${language}`,
                "/en"
              )}`}
            />
            <script>
              {((): any => {
                if (
                  typeof window !== "undefined" &&
                  (window as any).ReboundSettings === undefined
                ) {
                  (window as any).ReboundSettings = {
                    publicToken: "rkdd6ytzqx6geaaylg2sbm",
                    email: emailValue,
                    appearance: "custom",
                    complete(response: any) {
                      if (response.inactive) {
                        const { email } = response.data;
                        toast(
                          "error",
                          `${t(
                            "Письмо не может быть доставлено на адрес"
                          )} ${email}. ${t(
                            "Пожалуйста, обратитесь в службу клиентского сервиса для смены адреса электронной почты."
                          )}`
                        );
                      }
                    },
                  };
                  (function (r, e, b, o, u, n, d): any {
                    if ((r as any).Rebound) return;
                    // @ts-ignore
                    d = function () {
                      // @ts-ignore
                      o = "script";
                      // @ts-ignore
                      u = e.createElement(o);
                      // @ts-ignore
                      u.type = "text/javascript";
                      // @ts-ignore
                      u.src = b;
                      // @ts-ignore
                      u.async = true;
                      // @ts-ignore
                      n = e.getElementsByTagName(o)[0];
                      // @ts-ignore
                      n.parentNode.insertBefore(u, n);
                    };
                    if ((r as any).attachEvent) {
                      (r as any).attachEvent("onload", d);
                    } else {
                      // @ts-ignore
                      r.addEventListener("load", d, false);
                    }
                  })(
                    window,
                    document,
                    "https://rebound.postmarkapp.com/widget/1.0"
                  );
                }
              })()}
            </script>
            {/* <script async src="https://lib.usedesk.ru/secure.usedesk.ru/widget_160828_27987.js"></script> */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'UA-191000450-1', {'user_id': ${
                    userID ? `'${userID}'` : "''"
                  } });
                  `,
              }}
            />
            <script>
              {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1269624140618912');
                fbq('track', 'PageView');
              `}
            </script>
            <noscript>
              {`<img
                height="1"
                width="1"
                alt="noscript"
                style="display:none"
                src="https://www.facebook.com/tr?id=1269624140618912&ev=PageView&noscript=1"
              />`}
            </noscript>
          </Head>
        </ConditionallyRender>
        <div
          className={`App ${isVisibleMobileMenu ? "App-fixed-height" : ""}${
            className ? ` ${className}` : ""
          }`}
        >
          <CssBaseline />
          <Popup />
          <Header />
          <div className={`main-layout ${isVisibleMobileMenu ? "hidden" : ""}`}>
            <ConditionallyRender client>
              {openedMobileSidebar && (
                <Sidebar style={windowWidth < 768 ? { width: "100%" } : {}} />
              )}
            </ConditionallyRender>
            <main className={`main ${isLanding(location) ? "main-ml0" : ""}`}>
              {children}
            </main>
          </div>
          {!cookieAccept ? <CookiePanel /> : null}
          {(() => {
            if (isLanding(location)) {
              if (
                legalFootersLocation.find(
                  (legalFooter) =>
                    legalFooter ===
                    (language === "en" ? location.replace("/en", "") : location)
                ) === undefined
              ) {
                return <Footer />;
              }
            } else {
              return null;
            }
          })()}
        </div>
        <ToastContainerHOC />
      </MuiThemeProvider>
    );
  }
}
function mapStateToProps(state: any) {
  const {
    isVisibleMobileMenu, language, location, asPath, windowWidth, openedMobileSidebar,
  } = state.info;
  const {
    id
  } = state.user;
  const { token, email } = state.user;
  return {
    isVisibleMobileMenu,
    language,
    location,
    openedMobileSidebar,
    windowWidth,
    token,
    email,
    asPath,
    id,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    hideMobileSidebar: () => dispatch({ type: infoConstants.CLOSE_MOBILE_SIDEBAR }),
    openMobileSidebar: () => dispatch({ type: infoConstants.OPEN_MOBILE_SIDEBAR }),
  };
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Layout));
