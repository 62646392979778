import React from "react";
import Link from "next/link";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import { withTranslation } from "@i18n";
import {
  footerBtc,
  footerLtc,
  footerMastercard,
  footerVisa,
  footerDownloadAppStore,
  footerDownloadGooglePlay,
  whiteLogo,
} from "@assets/img";
import Image from "next/image";
import { isPagePath, languageReturner } from "../../helpers";
import "./Footer.scss";
import "./mobile/index.scss";
import {
  footerFacebook,
  footerInstagram,
  footerYoutube,
  footerTwitter,
} from "../../assets/img/Footer";

function Footer(props: any) {
  const { isVisibleMobileMenu, language, t, location, country } = props;
  function linkReturner(url: any) {
    return `${languageReturner(language)}${url}`;
  }
  function getFooterThemeClassName() {
    if (
      isPagePath(location, "/:lang/joint-participation-binance") ||
      isPagePath(location, "/:lang/binance")
    )
      return "footer--dark";
    if (
      isPagePath(location, "/:lang/joint-participation-binance") ||
      isPagePath(location, "/:lang/minto")
    )
      return "footer--greenDark";
    return "footer--white";
  }
  if (isVisibleMobileMenu) return null;
  return (
    <footer className={`footer-element ${getFooterThemeClassName()}`}>
      <Container className="footer-container">
        <div id="footer">
          <div className="footer-top">
            <div className="footer-logo-container">
              <Link href="/" as={`${languageReturner(language)}`}>
                <a>
                  <Image
                    className="footer-logo"
                    src={whiteLogo}
                    alt="CryptoUniverse"
                    width="230"
                    height="35"
                  />
                </a>
              </Link>
            </div>
            <div className="footer-menu">
              <ul>
                <li>
                  <Link href="/cloudmining" as={linkReturner("/cloudmining")}>
                    <a>{t("Облачный майнинг")}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/miners" as={linkReturner("/miners")}>
                    <a>{t("Купить майнер")}</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href="/joint-participation"
                    as={linkReturner("/joint-participation")}
                  >
                    <a>{t("Совместные покупки")}</a>
                  </Link>
                </li>
                <li className="footer-menu-li-soon">
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    {t("Подобрать контракт")}{" "}
                    <span className="footer-menu-soon">{t("Скоро")}</span>
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/news" as={linkReturner("/news")}>
                    <a>{t("Новости и акции")}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/contacts" as={linkReturner("/contacts")}>
                    <a>{t("Контакты")}</a>
                  </Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href='https://cryptouniverse.freshdesk.com/support/tickets/new'
                  >
                    {t("Служба поддержки")}
                  </a>
                </li>
                <li>
                  <a
                    href='https://cryptouniverse.freshdesk.com/support/tickets/new'
                  >
                    {t("FAQ")}
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <Link href="/terms" as={linkReturner("/terms")}>
                    <a>{t("Условия использования")}</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href="/privacy-policy"
                    as={linkReturner("/privacy-policy")}
                  >
                    <a>{t("Принципы конфиденциальности")}</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href="/cookie-policy"
                    as={linkReturner("/cookie-policy")}
                  >
                    <a>{t("Использование файлов Cookie")}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/documents" as={linkReturner("/documents")}>
                    <a>
                      {t("Все правовые документы")}{" "}
                      <i className="fal fa-angle-right" />
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-middle">
            <div className="footer-payments">
              <Image src={footerVisa} alt="Visa" width="62" height="40" />
              <Image
                src={footerMastercard}
                alt="Mastercard"
                width="62"
                height="40"
              />
              <Image src={footerBtc} alt="BTC" width="40" height="40" />
              <Image src={footerLtc} alt="LTC" width="40" height="40" />
            </div>
            <div className="footer-social-links">
              <a
                target="_blank"
                href="https://twitter.com/1cryptouniverse"
                aria-label="twitter"
                rel="nofollow noopener noreferrer"
              >
                <Image
                  src={footerTwitter}
                  alt="twitter"
                  width="48"
                  height="48"
                />
              </a>
              {country && country !== "RU" && (
                <a
                  target="_blank"
                  href="https://www.facebook.com/Cryptouniverseio-605796216562074/"
                  aria-label="facebook"
                  rel="nofollow noopener noreferrer"
                >
                  <Image
                    src={footerFacebook}
                    alt="facebook"
                    width="48"
                    height="48"
                  />
                </a>
              )}

              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCGF5PWBbJFVuPRsEEnsVxvA"
                aria-label="youtube"
                rel="nofollow noopener noreferrer"
              >
                <Image
                  src={footerYoutube}
                  alt="youtube"
                  width="48"
                  height="48"
                />
              </a>
              {country && country !== "RU" && (
                <a
                  target="_blank"
                  href="https://www.instagram.com/cryptouniverse_cloudmining/"
                  aria-label="intagram"
                  rel="nofollow noopener noreferrer"
                >
                  <Image
                    src={footerInstagram}
                    alt="instagram"
                    width="48"
                    height="48"
                  />
                </a>
              )}
            </div>
            <div className="footer-app-download">
              <a
                target="_blank"
                href="https://apps.apple.com/ru/app/apple-store/id1497868518"
                className="footer-button-disabled"
                rel="nofollow noopener noreferrer"
              >
                <span>{t("Скоро")}</span>
                <Image
                  src={footerDownloadAppStore}
                  alt="App Store"
                  width="160"
                  height="48"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.cryptouniverse"
                className="footer-button-disabled"
                rel="nofollow noopener noreferrer"
              >
                <span>{t("Скоро")}</span>
                <Image
                  src={footerDownloadGooglePlay}
                  alt="Google Play"
                  width="160"
                  height="48"
                />
              </a>
            </div>
          </div>
          <div className="footer-bottom">
            <div>{t('© Cryptouniverse. All rights reserved. 2017-2023')}</div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
function mapStateToProps(state: any) {
  const { isVisibleMobileMenu, language, location, country } = state.info;
  return {
    isVisibleMobileMenu,
    language,
    location,
    country,
  };
}
export default withTranslation("common")(connect(mapStateToProps)(Footer));
