import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';

const isDev = process.env.NODE_ENV !== 'production';
const isWindow = typeof window !== 'undefined';
const isDevToolsExtension = isDev && isWindow && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = isDevToolsExtension || compose;
const makeStore = (initialState = {}) => createStore(reducers, initialState, composeEnhancers(applyMiddleware(reduxThunk)));
export default makeStore;
