import { infoConstants } from '../constants';

const changeAsPath = (asPath: any) => ({
  type: infoConstants.CHANGE_ASPATH,
  asPath
});
const changeLocation = (location: any) => ({
  type: infoConstants.CHANGE_LOCATION,
  location
});
const showMobileNavigationMenu = () => ({
  type: infoConstants.SHOW_MOBILE_NAVIGATION_MENU
});
const hideMobileNavigationMenu = () => ({
  type: infoConstants.HIDE_MOBILE_NAVIGATION_MENU
});
const changeWindowWidth = (width: any) => ({
  type: infoConstants.CHANGE_WINDOW_WIDTH,
  width
});
const setMobileDevice = (isMobile: any) => ({
  type: infoConstants.SET_MOBILE_DEVICE,
  isMobile
});
const changeLanguage = (language: any) => ({
  type: infoConstants.CHANGE_LANGUAGE,
  language
});
const openMobileSidebar = () => ({ type: infoConstants.OPEN_MOBILE_SIDEBAR });
const closeMobileSidebar = () => ({ type: infoConstants.CLOSE_MOBILE_SIDEBAR });
const getCoinRates = (coinRates: any) => ({
  type: infoConstants.GET_COIN_RATES,
  coinRates
});
const getCurrencyRates = (currencyRates: any) => ({
  type: infoConstants.GET_CURRENCY_RATES,
  currencyRates
});
const saveMoneType = (moneyType: any) => ({
  type: infoConstants.SET_REDUX_MONEY_TYPE,
  moneyType
});
const saveWalletLockUntil = (walletLockUntil: any) => ({
  type: infoConstants.SET_WALLET_LOCK_UNTIL,
  walletLockUntil
});
const changeStateFullProfile = (newState: any) => ({
  type: infoConstants.CHANGE_STATE_FULL_PROFILE,
  newState
});
const setVerificationStatusCode = (code: any) => ({
  type: infoConstants.SET_VERIFICATION_STATUS_CODE,
  code
});
const showElectricityMessage = (showElectricityMessage: any) => ({
  type: infoConstants.SHOW_ELECTRICITY_MESSAGE,
  showElectricityMessage
});
const changeCountry = (country: any) => ({
  type: infoConstants.CHANGE_COUNTRY,
  country
});

const infoActions = {
  changeAsPath,
  changeLanguage,
  changeLocation,
  showMobileNavigationMenu,
  hideMobileNavigationMenu,
  changeWindowWidth,
  openMobileSidebar,
  closeMobileSidebar,
  setMobileDevice,
  getCoinRates,
  getCurrencyRates,
  saveMoneType,
  saveWalletLockUntil,
  changeStateFullProfile,
  setVerificationStatusCode,
  showElectricityMessage,
  changeCountry
};
export default infoActions;
