import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Link from 'next/link';
import './error.scss';
import './mobile/index.scss';
import { Trans, withTranslation } from '@i18n';
import { vipBg3 } from '@assets/img';
import { toast } from '../../helpers';
import { UnderHeader, Btn } from '../../components';
import 'react-toastify/dist/ReactToastify.css';

const ErrorDiv = (props: any) => {
  useEffect(() => {
    document.title = '404';
    toast('error', 'Ошибка 404');
    toast('error', 'Запрашиваемая вами страница не найдена!');
  }, []);
  return (
    <div className="error404">
      <UnderHeader className="error_container" style={{ backgroundImage: `url(${vipBg3})` }}>
        <div>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
          >
            <h1 className="static-page-title error_container_title"><Trans>Ошибка 404</Trans></h1>
            <p className="error_container_text"><Trans>Страница, на которую вы хотели перейти, не найдена. Возможно, введен некорректный адрес, или страница была удалена</Trans></p>
          </Grid>
          <Link href="/">
            <Btn color="white" flex justifyContent="space-between">
              <Trans>На главную</Trans>
              <i className="fa fa-caret-right ml-auto" />
            </Btn>
          </Link>
        </div>
      </UnderHeader>
    </div>
  );
};
ErrorDiv.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});
export default withTranslation('common')(ErrorDiv);
