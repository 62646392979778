import React, { Component } from 'react';
import './ToastContainerHOC.scss';
import './mobile/index.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToastContainerHOC extends Component {
  render() {
    return (
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        pauseOnHover={false}
      />
    );
  }
}
export default ToastContainerHOC;
