import Cookies from 'universal-cookie';
import { i18n } from '../../i18n';
import { languageObj } from './languagesData';

export const getShortLanguageCode = () => {
  const longLanguageCode = i18n.language;
  let shortLanguageCode = 'ru';
  Object.keys(languageObj).map((code: string) => {
    if ((languageObj as any)[code].includes(longLanguageCode)) shortLanguageCode = code;
  });
  return shortLanguageCode;
}

export const changeLanguage = (locale: any) => {
  i18n.changeLanguage(locale);
};

export const getLangFromBrowserStore = () => {
  const cookies = new Cookies();
  return cookies.get('next-i18next');
};
