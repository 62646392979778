const infoConstants = {
  CHANGE_ASPATH: 'CHANGE_ASPATH',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  SHOW_MOBILE_NAVIGATION_MENU: 'SHOW_MOBILE_NAVIGATION_MENU',
  HIDE_MOBILE_NAVIGATION_MENU: 'HIDE_MOBILE_NAVIGATION_MENU',
  CHANGE_WINDOW_WIDTH: 'CHANGE_WINDOW_WIDTH',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  SET_MOBILE_DEVICE: 'SET_MOBILE_DEVICE',
  OPEN_MOBILE_SIDEBAR: 'OPEN_MOBILE_SIDEBAR',
  CLOSE_MOBILE_SIDEBAR: 'CLOSE_MOBILE_SIDEBAR',
  GET_COIN_RATES: 'GET_COIN_RATES',
  GET_CURRENCY_RATES: 'GET_CURRENCY_RATES',
  SET_REDUX_MONEY_TYPE: 'SET_REDUX_MONEY_TYPE',
  SET_WALLET_LOCK_UNTIL: 'SET_WALLET_LOCK_UNTIL',
  CHANGE_STATE_FULL_PROFILE: 'CHANGE_STATE_FULL_PROFILE',
  SET_VERIFICATION_STATUS_CODE: 'SET_VERIFICATION_STATUS_CODE',
  SHOW_ELECTRICITY_MESSAGE: 'SHOW_ELECTRICITY_MESSAGE',
  CHANGE_COUNTRY: 'CHANGE_COUNTRY'
};
export default infoConstants;
