import { infoConstants } from '../constants';

const DEFAULT_STATE = {
  asPath: null,
  location: null,
  isVisibleMobileMenu: false,
  windowWidth: null,
  language: 'en',
  openedMobileSidebar: true,
  isMobile: null,
  coinRates: null,
  currencyRates: null,
  moneyType: null,
  walletLockedUntil: null,
  fullProfile: null,
  verificationCode: null,
  showElectricityMessage: false,
  country: null,
};

export function info(state = DEFAULT_STATE, action: any) {
  switch (action.type) {
    case infoConstants.CHANGE_ASPATH:
      return {
        ...state,
        asPath: action.asPath
      };
    case infoConstants.CHANGE_LOCATION:
      return {
        ...state,
        location: action.location
      };
    case infoConstants.SHOW_MOBILE_NAVIGATION_MENU:
      return {
        ...state,
        isVisibleMobileMenu: true
      };
    case infoConstants.HIDE_MOBILE_NAVIGATION_MENU:
      return {
        ...state,
        isVisibleMobileMenu: false
      };
    case infoConstants.CHANGE_WINDOW_WIDTH:
      return {
        ...state,
        windowWidth: action.width
      };
    case infoConstants.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    case infoConstants.CLOSE_MOBILE_SIDEBAR:
      return {
        ...state,
        openedMobileSidebar: false
      };
    case infoConstants.OPEN_MOBILE_SIDEBAR:
      return {
        ...state,
        openedMobileSidebar: true
      };
    case infoConstants.SET_MOBILE_DEVICE:
      return {
        ...state,
        isMobile: action.isMobile
      };
    case infoConstants.GET_COIN_RATES:
      return {
        ...state,
        coinRates: action.coinRates
      };
    case infoConstants.GET_CURRENCY_RATES:
      return {
        ...state,
        currencyRates: action.currencyRates
      };
    case infoConstants.SET_REDUX_MONEY_TYPE: {
      return {
        ...state,
        moneyType: action.moneyType
      };
    }
    case infoConstants.SET_WALLET_LOCK_UNTIL: {
      return {
        ...state,
        walletLockedUntil: action.walletLockUntil
      };
    }
    case infoConstants.CHANGE_STATE_FULL_PROFILE: {
      return {
        ...state,
        fullProfile: action.newState
      };
    }
    case infoConstants.SET_VERIFICATION_STATUS_CODE: {
      return {
        ...state,
        verificationCode: action.code
      };
    }
    case infoConstants.SHOW_ELECTRICITY_MESSAGE: {
      return {
        ...state,
        showElectricityMessage: action.showElectricityMessage
      };
    }
    case infoConstants.CHANGE_COUNTRY:
      return {
        ...state,
        country: action.country
      };
    default:
      return state;
  }
}
