import { userConstants } from '../constants';

const DEFAULT_STATE = {
  avatar: null,
  isUserLoggedIn: false,
  balance: {
    btc: {
      amount: null,
      usdAmount: null
    },
    ltc: {
      amount: null,
      usdAmount: null
    }
  },
  accrualsLength: null,
  accrualsLengthNew: null,
  referalLengthNew: null,
  referalLength: null,
  firstOperation: null,
  newOperation: null,
  id: null
};

export function user(state = DEFAULT_STATE, action: any) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        email: action.email
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        email: null
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        token: null,
        email: null
      };
    case userConstants.VERIFICATION_SUCCESS:
      return {
        ...state,
        verification: action.value
      };
    case userConstants.AVATAR_UPDATE:
      return {
        ...state,
        avatar: action.avatar
      };
    case userConstants.USER_TYPE_AND_2FA:
      return {
        ...state,
        userType: action.userType,
        fa2: action.fa2
      };
    case userConstants.BALANCE_UPDATE:
      return {
        ...state,
        balance: action.balance
      };
    case userConstants.SET_INVOICE:
      return {
        ...state,
        invoice: action.invoice
      };
    case userConstants.SET_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: action.isUserLoggedIn
      };
    case userConstants.SET_USER_ACCRUALS_LENGTH: {
      return {
        ...state,
        accrualsLength: action.accrualsLength
      };
    }
    case userConstants.SET_USER_ACCRUALS_LENGTH_NEW: {
      return {
        ...state,
        accrualsLengthNew: action.accrualsLength
      };
    }
    case userConstants.SET_USER_REFERAL_LENGTH: {
      return {
        ...state,
        referalLength: action.referalsLength
      };
    }
    case userConstants.SET_USER_REFERAL_LENGTH_NEW: {
      return {
        ...state,
        referalLengthNew: action.referalsLength
      };
    }
    case userConstants.SET_USER_FIRST_OPERATION: {
      return {
        ...state,
        firstOperation: action.firstOperation
      };
    }
    case userConstants.SET_USER_NEW_OPERATION: {
      return {
        ...state,
        newOperation: action.newOperation
      };
    }
    case userConstants.SET_USER_EMAIL: {
      return {
        ...state,
        email: action.email
      }
    }
    case userConstants.SET_USER_ID: {
      return {
        ...state,
        id: action.id
      }
    }
    default:
      return state;
  }
}
