import React from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import Router, { withRouter } from 'next/router';
import withRedux from 'next-redux-wrapper';
import Cookies from 'universal-cookie';
import { setCookie } from 'nookies';
import { i18n, appWithTranslation, Trans } from '@i18n';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { CountryRegionData } from 'react-country-region-selector';
import { makeStore } from '../redux';
import { infoActions, userActions } from '../redux/actions';
import { getLangFromBrowserStore, changeLanguage } from '../locales/i18n';
import { languageObj } from '../locales/languagesData';
import { Layout } from '../layout';
import Error from './_error';
import { userConstants, infoConstants } from '../redux/constants';
import {
  axios, isDashboard, ee, isLanding, isPagePath, setAmountOfNewOperations, languageReturner,
} from '../helpers';
import { userService } from '../services';

if (process.env.SERVER_TYPE !== 'local') {
  Bugsnag.start({
    apiKey: 'db99a7980e11a62ce7ac0a6c86d11e8c',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.SERVER_TYPE === 'prod' ? 'production' : 'testServer',
    appVersion: '3.2.3',
  });
}
// @ts-ignore
const ErrorBoundary = process.env.SERVER_TYPE !== 'local' && Bugsnag.getPlugin('react').createErrorBoundary(React);
class MyApp extends App<any, any> {
  cleanupFunction: any;

  updateDimensionDuration: any;

  static async getInitialProps({ Component, ctx }: any) {
    let pageProps = {};
    const currentLanguage = ctx.req ? ctx.req.language : i18n.language;
    const location = ctx.pathname;
    const locationArr = location.split('/');
    const pageName = locationArr[locationArr.length - 1];
    let { asPath, query } = ctx;
    let statusCode;
    if (Component.getInitialProps) {
      try {
        pageProps = await Component.getInitialProps(ctx);
        if ((pageProps as any).statusCode === 404 && ctx.isServer) {
          statusCode = 404;
          ctx.res.statusCode = 404;
        }
      } catch (e) {
        // Error
      }
    }
    const cookies = new Cookies(ctx.isServer ? ctx.req.headers.cookie : {});
    const {
      utm_source, utm_medium, utm_campaign, utm_content, utm_term,
    } = query;
    if (utm_source) {
      setCookie(ctx, 'utm_source', utm_source, { path: '/' });
    }
    if (utm_medium) {
      setCookie(ctx, 'utm_medium', utm_medium, { path: '/' });
    }
    if (utm_campaign) {
      setCookie(ctx, 'utm_campaign', utm_campaign, { path: '/' });
    }
    if (utm_content) {
      setCookie(ctx, 'utm_content', utm_content, { path: '/' });
    }
    if (utm_term) {
      setCookie(ctx, 'utm_term', utm_term, { path: '/' });
    }
    const cookieAccept = cookies.get('cookieAccept');
    const cookieToken = cookies.get('token');
    const allUserFieldsAreFilled = cookies.get('allUserFieldsAreFilled') === 'true';
    const isApproved = cookies.get('is_approved') === 'true';
    const firstOperation = cookies.get('first_operation');
    let userAgent;
    if (ctx.req) {
      userAgent = ctx.req.headers['user-agent'];
    } else {
      userAgent = navigator.userAgent;
    }
    // Редирект если email не подтвержден
    if (location.includes('dashboard') && !isApproved && !!cookieToken) {
      if (ctx.isServer) {
        ctx.res.writeHead(302, {
          Location: `/${currentLanguage}/email-verification`,
        });
        ctx.res.end();
      } else {
        Router.push('/email-verification', `/${currentLanguage}/email-verification`);
        Router.push('/', `/${currentLanguage}`);
      }
    }
    if (location.includes('dashboard') && !location.includes('honos') && !cookieToken) {
      Object.keys(languageObj).map((langKey) => {
        const assPathArr = asPath.split('/').filter((x: any) => x);
        if (langKey === assPathArr[0]) {
          asPath = `/${assPathArr.slice(1).join('/')}`;
        }
      });
      if (ctx.isServer) {
        setCookie(ctx, 'redirect', asPath, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });
        ctx.res.writeHead(302, {
          Location: `/${currentLanguage}/login`,
        });
        ctx.res.end();
      } else {
        cookies.set('redirect', asPath, { path: '/' });
        Router.push(`/${currentLanguage}/login`);
      }
      return {
        pageProps,
        currentLanguage,
        location,
        pageName,
        cookieAccept,
        statusCode,
        userAgent,
        cookieToken,
        firstOperation,
        cookies,
        asPath: ctx.asPath,
      };
    }
    return {
      pageProps,
      currentLanguage,
      location,
      pageName,
      cookieAccept,
      statusCode,
      userAgent,
      cookieToken,
      firstOperation,
      cookies,
      asPath: ctx.asPath,
    };
  }

  constructor(props: any) {
    super(props);
    const { location } = props;
    this.cleanupFunction = false;
    this.updateDimensionDuration = false;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.isAllFieldsAreFilled = this.isAllFieldsAreFilled.bind(this);
    this.checkIsUserEmailConfirmed = this.checkIsUserEmailConfirmed.bind(this);
    this.checkIfUserGoToDashboard = this.checkIfUserGoToDashboard.bind(this);
    this.checkBinanceSubdomain = this.checkBinanceSubdomain.bind(this);
    this.getAccrualsLength = this.getAccrualsLength.bind(this);
    this.getCoinRates();
    this.getCurrencyRates();
    this.setPathname();
    this.setAsPath();
    this.setLanguage();
    this.detectDevice();
    this.setUserLoggedIn();
    if (isDashboard(location) && !this.cleanupFunction) {
      this.setDashboardLanguage();
      this.getCoinRates();
      this.getCurrencyRates();
      this.getAccrualsLength();
      this.getReferalsLength();
      this.getControlPanelNotificationNumber();
      this.setUserType();
    }
    this.setLandingLanguge();
    if ((process as any).browser) {
      if (isDashboard(location)) {
        if (userService.isUserHasAvatar()) {
          this.setUserAvatar();
        } else {
          this.getUserAvatar();
        }
      }
    }
    this.updateDimensions();
    this.setUserBalance();
  }

  componentDidUpdate() {
    this.setLanguage();
    this.setUserBalance();
  }

  checkIsUserEmailConfirmed(location: any) {
    const { cookieToken } = this.props;
    const cookies = new Cookies();
    const language = cookies.get('next-i18next');
    const isApproved = cookies.get('is_approved') === 'true';
    if (!isApproved && isDashboard(location) && !!cookieToken) {
      window.location.replace(`${languageReturner(language)}/email-verification`);
    }
  }

  checkBinanceSubdomain(location: string) {
    // Редирект на основной домен с поддомена https://binance.cryptouniverse.io/
    // если переходим по ссылкам

    if (window.location.href.includes(`${process.env.SITE_BINANCE_SUBDOMAIN}.`)) {
      const languagesPaths = ['/', ...Object.keys(languageObj).map((val) => `/${val}`)];
      let { href } = window.location;

      let skip = false;
      Object.keys(languageObj).map((val) => {
        if (skip) return false;
        if (href.includes(`/${val}`)) {
          href = href.replace(`/${val}`, '');
        }
      });

      let newUrl = href.replace(`${process.env.SITE_BINANCE_SUBDOMAIN}.`, '');

      newUrl = newUrl.replace(/\/$/, '');
      newUrl += location;

      if (!languagesPaths.includes(location)) {
        window.location.href = newUrl;
        // @ts-ignore
        throw new Error('Abort');
      }
    }
  }

  componentDidMount() {
    const isProduction = process.env.NODE_ENV === 'production';
    const { store } = this.props;
    const { dispatch } = store;
    const jssStyles = document.querySelector('#jss-server-side');

    this.loadFonts();
    axios
      .get('https://pro.ip-api.com/json/?key=6LE7l9GzpVHYTDI')
      .then((res) => {
        dispatch(infoActions.changeCountry(res.data?.countryCode));
        if (isProduction) {
          this.loadScripts(res.data?.countryCode);
        }
      })
      .then((err) => {
        console.log(err);
      });
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    this.refreshChunkStyle();
    const { isMobile } = store.getState().info;
    ee.on('login', () => {
      this.getCoinRates();
      this.getCurrencyRates();
      this.getAccrualsLength();
      this.getReferalsLength();
      this.getControlPanelNotificationNumber();
      this.setUserType();
      if (userService.isUserHasAvatar()) {
        this.setUserAvatar();
      } else {
        this.getUserAvatar();
      }
      this.updateDimensions();
      this.setUserBalance();
    });
    ee.on('updateProfile', () => {
      this.setUserType();
    });
    Router.events.on('routeChangeStart', this.checkIsUserEmailConfirmed);
    Router.events.on('routeChangeStart', this.checkIfUserGoToDashboard);
    Router.events.on('routeChangeComplete', () => {
      const { location } = this.props;
      const doc: any = document.querySelector('body');
      doc.click();
      this.setPathname.call(this);
      this.setAsPath.call(this);
      dispatch(infoActions.hideMobileNavigationMenu());
      if (isMobile) dispatch(infoActions.closeMobileSidebar());
      if (isDashboard(location)) {
        this.setDashboardLanguage();
      }
      this.setLandingLanguge();
    });

    Router.events.on('routeChangeStart', this.checkBinanceSubdomain);

    window.addEventListener('resize', this.updateDimensions);
    const cookies = new Cookies();
    dispatch({
      type: userConstants.LOGIN_SUCCESS, token: cookies.get('token'), email: cookies.get('email'),
    });
    dispatch({
      type: userConstants.USER_TYPE_AND_2FA, userType: cookies.get('usertype'), fa2: cookies.get('fa2'),
    });
    if (cookies.get('base_currency') !== undefined) {
      dispatch({
        type: infoConstants.SET_REDUX_MONEY_TYPE, moneyType: cookies.get('base_currency'),
      });
    }
    if (cookies.get('accruals_length') !== undefined) {
      dispatch(userActions.setAccrualsLength(Number(cookies.get('accruals_length'))));
    }
    if (cookies.get('fullProfile') !== undefined) {
      dispatch(infoActions.changeStateFullProfile(cookies.get('fullProfile') === 'true'));
    }
    if (cookies.get('referal_length') !== undefined) {
      dispatch(userActions.setReferallsLength(Number(cookies.get('referal_length'))));
    }
    if (cookies.get('first_operation') !== undefined) {
      dispatch(userActions.setFirstOperationID(cookies.get('first_operation')));
    }
    if (cookies.get('showElectricityMessage') !== undefined) {
      dispatch(infoActions.showElectricityMessage(cookies.get('showElectricityMessage') === 'true'));
    }
    if (cookies.get('verification') === 'true') {
      dispatch(userActions.verification(true));
    } else {
      dispatch(userActions.verification(false));
    }
  }

  componentWillUnmount() {
    this.cleanupFunction = true;
    Router.events.off('routeChangeStart', this.checkIsUserEmailConfirmed);
    Router.events.off('routeChangeStart', this.checkIfUserGoToDashboard);
  }

  checkIfUserGoToDashboard(location: any) {
    const cookies = new Cookies();
    const is_approved = cookies.get('is_approved') === 'true';
    const { router } = this.props;
    const locationBefore = router.asPath;
    if (isLanding(locationBefore) && isDashboard(location) && is_approved) {
      ee.emit('login');
    }
  }

  setUserLoggedIn() {
    const { store, cookieToken } = this.props;
    const { dispatch } = store;
    dispatch({
      type: userConstants.SET_USER_LOGGED_IN, isUserLoggedIn: !!cookieToken,
    });
  }

  detectDevice(userAgentProps?: any) {
    const { userAgent, store } = this.props;
    const { dispatch } = store;
    if (userAgent !== undefined || userAgentProps !== undefined) {
      const isMobile = Boolean((userAgentProps || userAgent).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
      dispatch(infoActions.setMobileDevice(isMobile));
      if (!(process as any).browser) {
        dispatch(infoActions.changeWindowWidth(isMobile ? 480 : 1920));
      }
    }
  }

  getCurrencyRates() {
    const { store } = this.props;
    const { dispatch } = store;
    try {
      axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/currency/rates`).then((data) => {
        if (data !== undefined && data !== null && data.data !== undefined) {
          dispatch(infoActions.getCurrencyRates(data.data.data));
          const cookies = new Cookies();
          cookies.set('currencyRates', data.data.data, { path: '/' });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  getCoinRates() {
    const { store } = this.props;
    const { dispatch } = store;
    try {
      axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/coin/rates`).then((data) => {
        try {
          if (data.data !== undefined) {
            dispatch(infoActions.getCoinRates(data.data.data));
            const cookies = new Cookies();
            cookies.set('coinRates', data.data.data, { path: '/' });
          }
        } catch (e) {
          return null;
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  getAccrualsLength() {
    const { store } = this.props;
    const { dispatch } = store;
    try {
      axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/deposits/user/safe`).then((res) => {
        if (res && res.data && res.data.data) {
          try {
            axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/deposits/transactions/${res.data.data.id}`)
              .then((res) => {
                if (res && res.data && res.data.data) {
                  dispatch(userActions.setNewAccrualsLength(res.data.data.length));
                }
              });
          } catch (e) {
            console.log(e);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  getReferalsLength() {
    const { store } = this.props;
    const { dispatch } = store;
    try {
      axios.get('/cache/api/dashboard/referral/list')
        .then((data) => {
          if (data.data !== undefined && data.data.data) {
            dispatch(userActions.setNewReferallsLength(Object.keys(data.data.data).length));
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  getControlPanelNotificationNumber() {
    const { store, location } = this.props;
    const { dispatch } = store;
    if (isPagePath(location, ['/dashboard/balances', '/dashboard/wallet/[wallet]', '/dashboard/history'])) return false;
    try {
      const endDateTime = moment();
      const startDateTime = moment().subtract(1, 'months');
      axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/dashboard/operations/20?start=${startDateTime.format('DD.MM.YYYY')}&end=${endDateTime.format('DD.MM.YYYY')}`)
        .then((data) => {
          if (data.data && data.data.data) {
            const operations = data.data.data;
            setAmountOfNewOperations(dispatch, operations);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  isAllFieldsAreFilled(fields: { [index: string]: any } = {}) {
    const requiredFields: string[] = ['address', 'city', 'zip', 'country', 'state', 'date', 'firstName', 'secondName'];
    let fieldsAreFilled = true;
    requiredFields.forEach((field) => {
      if (!fields[field]) fieldsAreFilled = false;
    });
    return fieldsAreFilled;
  }

  setUserType() {
    const { store } = this.props;
    const { dispatch } = store;
    const cookies = new Cookies();
    axios
      .get(`${process.env.REACT_APP_API_SERVER_URL}/api/profile`)
      .then((res) => {
        if (res !== undefined && res !== null && res.data !== undefined) {
          const { data } = res.data;
          const receivedData = {
            address: data.address1,
            city: data.city,
            country: CountryRegionData.find((unit) => unit[0] === data.country) ? data.country : '',
            date: data.dob,
            email: data.email,
            firstName: data.firstname,
            secondName: data.lastname,
            state: CountryRegionData.find((unit) => unit[0] === data.country) ? data.state : '',
            zip: data.zip,
            level: data.level,
            timezone: data.timezone,
          };
          if (data.id) {
            dispatch(userActions.setUserId(data.id));
            cookies.set('userID', data.id, { path: '/' });
          }
          if (data.verification_status_code === 1) {
            dispatch(userActions.verification(true));
            cookies.set('verification', true, { path: '/' });
          } else {
            dispatch(userActions.verification(false));
            cookies.set('verification', false, { path: '/' });
          }
          if (this.isAllFieldsAreFilled(receivedData)) {
            cookies.set('fullProfile', true, { path: '/' });
            dispatch(infoActions.changeStateFullProfile(true));
          } else {
            cookies.set('fullProfile', false, { path: '/' });
            dispatch(infoActions.changeStateFullProfile(false));
          }
          dispatch(userActions.userTypeAnd2FA(data.is_manager, data.is_2fa));
          dispatch(infoActions.saveWalletLockUntil(data.wallet_locked_until !== 0 ? data.wallet_locked_until : null));
          dispatch(infoActions.setVerificationStatusCode(data.verification_status_code));
          cookies.set('verificationCode', data.verification_status_code, { path: '/' });
        }
      });
  }

  setUserBalance() {
    const { store, location } = this.props;
    const { dispatch } = store;
    const { user } = store.getState();
    if (isDashboard(location) && !user.balance.btc.amount) {
      userService.getUserBalance()
        .then((res) => {
          if (res) dispatch({ type: userConstants.BALANCE_UPDATE, balance: res });
        }, (error) => {
          throw error;
        });
    }
  }

  setUserAvatar() {
    const { store } = this.props;
    const { dispatch } = store;
    dispatch(userActions.setUserAvatar());
  }

  getUserAvatar() {
    const { store } = this.props;
    const { dispatch } = store;
    dispatch(userActions.getUserAvatar());
  }

  refreshChunkStyle() {
    if (module.hot) {
      module.hot.addStatusHandler((status) => {
        if (typeof window !== 'undefined' && status === 'ready') {
          (window as any).__webpack_reload_css__ = true;
        }
      });
    }
  }

  loadScripts(country: string) {
    /* Facebook Pixel Code */
    const facebookLink = document.createElement('script');
    facebookLink.defer = true;
    facebookLink.type = 'text/javascript';
    facebookLink.innerHTML = "!function (f, b, e, v, n, t, s) { if (f.fbq) return; n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) }; if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0; t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s) }(window, document, 'script', 'facebook.js'); fbq('init', '368119403885143'); fbq('track', 'PageView');";
    /* Quora Pixel */
    const quoraLink = document.createElement('script');
    quoraLink.defer = true;
    quoraLink.type = 'text/javascript';
    quoraLink.innerHTML = "!function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', '/quora.js'); qp('init', '9ecafc40115c40e78ff19e96982ee389'); qp('track', 'ViewContent');";
    /* Quora Pixel Init */
    const quoraInitLink = document.createElement('script');
    quoraInitLink.defer = true;
    quoraInitLink.type = 'text/javascript';
    quoraInitLink.innerHTML = "qp('track', 'GenerateLead');";
    /* VK pixel */
    const vkLink = document.createElement('script');
    vkLink.defer = true;
    vkLink.type = 'text/javascript';
    vkLink.innerHTML = '!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="vk.js",t.onload=function(){VK.Retargeting.Init("VK-RTRG-264705-daIFE"),VK.Retargeting.Hit()},document.head.appendChild(t)}();';
    /* Tik tok */
    const tiktokLink = document.createElement('script');
    tiktokLink.defer = true;
    tiktokLink.type = 'text/javascript';
    tiktokLink.innerHTML = "(function(root) { var ta = document.createElement('script'); ta.type = 'text/javascript'; ta.async = true; ta.src = 'https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=BSGSOT2HO5CA4NQ7BNSG'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ta, s); })(window);";
    /* Yandex.Metrika counter */
    const yandexLink = document.createElement('script');
    yandexLink.defer = true;
    yandexLink.type = 'text/javascript';
    yandexLink.innerHTML = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");ym(74940400, "init", {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, ecommerce:"dataLayer"});';

    setTimeout(() => {
      country !== 'RU' &&  country !== 'CN' && document.head.appendChild(facebookLink);
      document.head.appendChild(quoraLink);
      document.head.appendChild(quoraInitLink);
      document.head.appendChild(vkLink);
      document.head.appendChild(tiktokLink);
      document.head.appendChild(yandexLink);
    }, 2500);
  }

  loadFonts() {
    /* Font Awesome */
    const fontAwesome = document.createElement('link');
    fontAwesome.href = '/static/styles/fonts.css';
    fontAwesome.rel = 'preload';
    fontAwesome.as = 'style';
    fontAwesome.onload = () => {
      fontAwesome.rel = 'stylesheet';
    };
    document.head.appendChild(fontAwesome);
    /* Motserrat */
    const montserratLink = document.createElement('link');
    montserratLink.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap';
    montserratLink.rel = 'stylesheet';
    document.head.appendChild(montserratLink);
    /* Roboto */
    const robotoLink = document.createElement('link');
    robotoLink.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap';
    robotoLink.rel = 'stylesheet';
    document.head.appendChild(robotoLink);
  }

  updateDimensions(e?: any) {
    const { store } = this.props;
    const { dispatch } = store;
    const timeOut = ((e && e.type === 'load') || e === undefined) ? 0 : 200;
    if (!this.updateDimensionDuration && process.browser) {
      this.updateDimensionDuration = true;
      if (timeOut === 0) {
        dispatch(infoActions.changeWindowWidth(window.innerWidth));
        this.updateDimensionDuration = false;
      } else {
        setTimeout(() => {
          dispatch(infoActions.changeWindowWidth(window.innerWidth));
          if (window.innerWidth > 1024) dispatch(infoActions.hideMobileNavigationMenu());
          this.detectDevice(navigator.userAgent);
          this.updateDimensionDuration = false;
        }, timeOut);
      }
    }
  }

  setPathname() {
    const { store, currentLanguage, location } = this.props;
    const { dispatch } = store;
    dispatch(infoActions.changeLocation(`/${currentLanguage}${location}`));
  }

  setAsPath() {
    const { store, asPath } = this.props;
    const { dispatch } = store;
    dispatch(infoActions.changeAsPath(asPath));
  }

  setDashboardLanguage() {
    const { store, currentLanguage } = this.props;
    const { dispatch } = store;
    if (!['ru', 'en'].includes(currentLanguage)) {
      changeLanguage('en');
      dispatch(infoActions.changeLanguage('en'));
    }
  }

  setLandingLanguge() {
    const { store, currentLanguage, location } = this.props;
    const { dispatch } = store;
    const changeLangToDefault = () => {
      changeLanguage('ru');
      dispatch(infoActions.changeLanguage('ru'));
    };
    if (String(location).endsWith('/joint-participation') || String(location).endsWith('/joint-participation-binance') || String(location).includes('/pool/')) {
      if (!['ru', 'en', 'zh'].includes(currentLanguage)) {
        changeLangToDefault();
      }
    }
    // if (String(location).endsWith('/hosting') || String(location).endsWith('/data-center')) {
    //   if (!['ru', 'zh'].includes(currentLanguage)) {
    //     changeLangToDefault();
    //   }
    // }
  }

  setLanguage() {
    const { store } = this.props;
    let { currentLanguage } = this.props;
    const { dispatch } = store;
    const cookies = new Cookies();
    const storeLocale = getLangFromBrowserStore();
    const findLocale = Object.keys(languageObj).find((key) => key === currentLanguage);
    if (storeLocale !== currentLanguage) {
      if (findLocale) {
        changeLanguage(currentLanguage);
        dispatch(infoActions.changeLanguage(currentLanguage));
      } else {
        currentLanguage = storeLocale;
        changeLanguage(currentLanguage);
        dispatch(infoActions.changeLanguage(currentLanguage));
      }
    } else if (findLocale) {
      changeLanguage(currentLanguage);
      dispatch(infoActions.changeLanguage(currentLanguage));
    } else {
      currentLanguage = storeLocale;
      changeLanguage(currentLanguage);
      dispatch(infoActions.changeLanguage(currentLanguage));
    }
  }

  getDarkThemeClass() {
    const { location } = this.props;
    if (isPagePath(location, '/joint-participation-binance') || isPagePath(location, '/binance')) return 'page--dark';
    return '';
  }

  render() {
    const {
      Component, pageProps, store, cookieAccept, statusCode, location, pageName,
    } = this.props;
    const content = (
      <Provider store={store}>
        <Layout
          cookieAccept={cookieAccept}
          className={`${this.getDarkThemeClass()} ${
            isLanding(location) ? "App-landing" : "App-dashboard"
          }`}
        >
          {!statusCode ? (
            <Component {...pageProps} />
          ) : (
            <Error status={statusCode} />
          )}
        </Layout>
      </Provider>
    );
    return (process.env.SERVER_TYPE === 'local' ? content : (
      // @ts-ignore
      <ErrorBoundary>
        {content}
      </ErrorBoundary>
    ));
  }
}
export default withRedux(makeStore, { debug: false })(withRouter(appWithTranslation(MyApp)));
