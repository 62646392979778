import { combineReducers } from 'redux';

import { user } from './user.reducer';
import { info } from './info.reducer';

const rootReducer = combineReducers({
  user,
  info
});

export default rootReducer;
