import React, { useEffect, useState, useCallback } from 'react';
import Router from 'next/router';
import { ee, popUpScrollForbiden } from '../../helpers';
import { useSelector } from 'react-redux';
import './Popup.scss';
import './mobile/index.scss';
import {
  Dialog, IconButton, Typography, withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Popup = () => {
  const { location, windowWidth } = useSelector((state: any) => state.info);
  const [popups, setPopup] = useState<{[index:string]:any}>({});
  const [openQueue, setOpenQueue] = useState<string[]>([]);
  const [updateQueue, setUpdateQueue] = useState<string[]>([]);
  const disallowScroll = () => {
    setTimeout(() => {
      document.body.style.maxHeight = '100vh';
      document.body.style.overflow = 'hidden';
    }, 0);
  };
  const allowScroll = () => {
    setTimeout(() => {
      document.body.style.maxHeight = '';
      document.body.style.overflow = 'visible';
    }, 0);
  };
  const createPopup = (params: any) => {
    const {
      id, title = '', content = '', actions = '', dialog = {}, dialogTitle = {}, dialogActions = {}, dialogContent = {}, deps = {},
    } = params;
    setPopup((prevState: {[index:string]:any}) => ({
      ...prevState,
      [id]: {
        opened: prevState[id] ? prevState[id].opened : false,
        title,
        content,
        actions,
        dialog,
        dialogTitle,
        dialogActions,
        dialogContent,
        deps,
      },
    }));
  };
  const updatePopup = (params: any) => {
    const { id } = params;
    const newParams = { ...params };
    delete newParams.id;
    setPopup((prevState:{[index:string]:any}) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        ...newParams,
      },
    }));
  };
  const closePopup = (id: any) => {
    if (id === 'all') {
      setPopup(() => ({}));
      return;
    }
    allowScroll();
    setPopup((prevState:{[index:string]:any}) => {
      const newState = { ...prevState };
      newState[id].opened = false;
      return ({
        ...newState,
      });
    });
  };
  const openPopup = (id: any) => {
    const popup = popups[id];
    popup.opened = true;
    disallowScroll();
    setPopup((prevState) => ({
      ...prevState,
      [id]: {
        ...popup,
      },
    }));
  };
  const addToOpenQueue = (id: any) => {
    setOpenQueue((prevState) => [
      ...prevState,
      id,
    ]);
  };
  const removeFromOpenQueue = (id: any) => {
    setOpenQueue((prevState) => {
      const newArr = prevState.slice();
      const index = newArr.indexOf(id);
      if (index > -1) {
        newArr.splice(index, 1);
      }
      return newArr;
    });
  };
  const addToUpdateQueue = (params: any) => {
    setUpdateQueue((prevState) => [
      ...prevState,
      params,
    ]);
  };
  const removeFromUpdateQueue = (id: any) => {
    setUpdateQueue((prevState) => {
      const newArr = prevState.slice();
      let index = null;
      newArr.forEach((updateObj, i) => {
        if ((updateObj as any).id === id) {
          index = i;
        }
      });
      if (index !== null) {
        newArr.splice(index, 1);
      }
      return newArr;
    });
  };
  useEffect(() => {
    Router.events.on('routeChangeStart', (nextLocation) => {
      const nextLocationArr = nextLocation.split('/');
      const locationArr = location.split('/');
      if (nextLocationArr[nextLocationArr.length - 1] !== locationArr[locationArr.length - 1]) closePopup('all');
    });
    ee.on('createPopup', (params: any) => {
      createPopup(params);
    });
    ee.on('openPopup', (id: any) => {
      if (popups[id]) {
        openPopup(id);
      } else {
        addToOpenQueue(id);
      }
    });
    ee.on('closePopup', (id: any) => {
      if (popups[id]) {
        closePopup(id);
      }
    });
    ee.on('updatePopup', (params: any) => {
      const { id } = params;
      if (popups[id]) {
        updatePopup(params);
      } else {
        addToUpdateQueue(params);
      }
    });
    (window as any).popupMounted = true;
    ee.emit('popupMounted', true);
  }, []);
  useEffect(() => {
    if (openQueue.length) {
      openQueue.forEach((id) => {
        if (popups[id]) {
          openPopup(id);
          removeFromOpenQueue(id);
        }
      });
    }
  }, [popups, openQueue]);
  useEffect(() => {
    if (updateQueue.length) {
      updateQueue.forEach((params: any) => {
        const { id } = params;
        if (popups[id]) {
          removeFromUpdateQueue(id);
          updatePopup(params);
        }
      });
    }
  }, [popups, updateQueue]);
  const styles = (theme: any) : any => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5,
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {

    const {
    // @ts-ignore
      children, classes, onClose, ...other
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6" className="popup-title">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.12536 3.125L26.8754 26.875" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M26.8759 3.12449L3.12586 26.8745" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogActions);
  useEffect(() => {
    Object.keys(popups).map((popupID) => {
      const popup = popups[popupID];
      const { opened } = popup;
      popUpScrollForbiden(windowWidth, [opened]);
    });
  }, [popups, windowWidth]);
  return (
    <>
      {Object.keys(popups).map((popupID) => {
        const popup = popups[popupID];
        const {
          title, content, actions, opened, dialog, dialogTitle, dialogActions, dialogContent, deps,
        } = popup;
        return (
          <Dialog onClose={() => closePopup(popupID)} aria-labelledby={popupID} open={opened} key={title.props ? title.props.children : title}>
            <DialogTitle id="customized-dialog-title" {...dialogTitle} onClose={() => closePopup(popupID)}>
              {title}
            </DialogTitle>
            <DialogContent {...dialogContent}>
              {(() => ((typeof content === 'function') ? content(deps) : content))()}
            </DialogContent>
            {actions && (
            <DialogActions {...dialogActions}>
              {actions}
            </DialogActions>
            )}
          </Dialog>
        );
      })}
    </>
  );
};
export default Popup;
