import React from 'react';
import Error404 from './error';

interface ErrorProps {
  status?: any
}

function Error(props: ErrorProps) {
  return (
    <div>
      <Error404 />
    </div>
  );
}
Error.getInitialProps = ({
  res,
  err,
}: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return {
    statusCode,
    namespacesRequired: ['common'],
  };
};
export default Error;
